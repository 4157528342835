*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

body,
html {
  height: 100%; }

html {
  font-size: 16px; }

body {
  line-height: 1;
  font-size: 16px;
  font-family: "Baloo Bhai", sans-serif;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  text-size-adjust: none; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0; }

ol li,
ul li {
  list-style: none; }

a {
  text-decoration: none; }

img {
  max-width: 100%; }

.slider {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: hidden; }
  .slider__container {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
    margin: 0;
    padding: 0;
    font-size: 0;
    list-style: none;
    white-space: nowrap; }
  .slider__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 16px;
    visibility: hidden; }
  .slider__item {
    position: relative;
    height: 100%;
    width: 100%;
    display: inline-block; }

.slider-controls {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translate3d(0px, -50%, 0px);
  z-index: 10; }
  .slider-controls > .previous,
  .slider-controls > .next {
    position: absolute;
    width: 64px;
    height: 64px;
    display: block;
    cursor: pointer;
    border: none;
    outline: none;
    user-select: none;
    background-size: 64px;
    background-color: transparent;
    background-repeat: no-repeat; }
  .slider-controls > .next {
    right: 20px;
    background-image: url("../images/arrow-right.svg"); }
  .slider-controls > .previous {
    left: 20px;
    background-image: url("../images/arrow-left.svg"); }

.slider-navigation {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 32px;
  text-align: center; }
  .slider-navigation > li {
    position: relative;
    display: inline-block;
    margin-right: 12px; }
    .slider-navigation > li > a {
      display: inline-block;
      width: 15px;
      height: 15px;
      border: 3px solid #FFF;
      border-radius: 50%;
      background: transparent;
      transition: all 0.4s ease; }
      .slider-navigation > li > a.is-active {
        background: rgba(255, 255, 255, 0.5);
        border-color: rgba(255, 255, 255, 0); }

.section {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box; }

.grid {
  display: table; }
  .grid > .column {
    width: 50%;
    display: table-cell;
    vertical-align: middle; }
    .grid > .column:first-child {
      text-align: right; }

.slide-burger {
  background: #CF624A; }

.slide-astronaut {
  background: #22231D; }

.slide-coffee {
  background: #836C54; }

.title {
  font-size: 60px;
  font-weight: 100;
  color: #FFF;
  text-transform: uppercase; }

@media only screen and (min-width: 768px) {
  .title {
    font-size: 7em; } }
